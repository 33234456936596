import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT';
import TextAreaInput from '../../../core/form/TextAreaInput';
import FileUploadInput from '../../../core/form/FileUploadInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, defaults, functions, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import {ContactUsPageServices, EntityServices } from '../../../../services';
import { WardenServices } from '../../../../services';
import PasswordInputRT from '../../../core/form/PasswordInputRT';

const AddWardenPopUp = ({ user, entity, concludeAddWardenPopUp }) => {

    console.log(entity);

    const [firstName, setFirstName] = useState("");
    const [hasFirstNameMessage, setHasFirstNameMessage] = useState(false);
    const [firstNameMessage, setFirstNameMessage] = useState("");
    const [firstNameMessageType, setFirstNameMessageType] = useState("");

    const [lastName, setLastName] = useState("");
    const [hasLastNameMessage, setHasLastNameeMessage] = useState(false);
    const [lastNameMessage, setLastNameMessage] = useState("");
    const [lastNameMessageType, setLastNameMessageType] = useState("");

    const [phoneNumber, setPhoneNumber] = useState();
    const [hasPhoneNumberMessage, setHasPhoneNumberMessage] = useState(false);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState("");
    const [phoneNumberMessageType, setPhoneNumberMessageType] = useState("");

    const [emailID, setEmailID] = useState();
    const [hasEmailIDMessage, setHasEmailIDMessage] = useState(false);
    const [emailIDMessage, setEmailIDMessage] = useState("");
    const [emailIDMessageType, setEmailIDMessageType] = useState("");

    const [password, setPassword] = useState('');
    const [hasPasswordMessage, setHasPasswordMessage] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState("");
    const [passwordMessageType, setPasswordMessageType] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [repeatPassword, setRepeatPassword] = useState('');
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [hasRepeatPasswordMessage, setHasRepeatPasswordMessage] = useState(false);
    const [repeatPasswordMessage, setRepeatPasswordMessage] = useState("");
    const [repeatPasswordMessageType, setRepeatPasswordMessageType] = useState("");
   
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isWardenFound, setIsWardenFound] = useState(false);

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const [warden, setWarden] = useState(null);

    const handleEmailValidation = async (e) => {
        e.preventDefault();

        if (emailID === "") {
            setHasEmailIDMessage(true);
            setEmailIDMessage("Email is required.");
            return;
        }

        setSubmitButtonLoading(false);

        try {
            const saveWardenReq = await WardenServices.saveWarden({
                email_id: emailID,
                token: user.access_token,
            });

            console.log(saveWardenReq);

            if(saveWardenReq.status === false){
                if(saveWardenReq.status_code === 204){
                    setIsEmailVerified(true); 
                    setIsWardenFound(false); 
                }
                else{
                    ToastAlert.notifyError(saveWardenReq.message);
                }
            }
            else{
                setWarden(saveWardenReq.data);
                setIsEmailVerified(true); 
                setIsWardenFound(true); 
            }
        }
        catch (error) {
            ToastAlert.notifyError("An error occurred while validating the email.");
            console.error(error);
        } 
    }

    const handleUpdateWardenToEntityReq = async (warden_id) => {

        try{
            const updateWardenToEntityReq = await EntityServices.updateWardenToEntity({
                org_id: entity.id,
                admin_id: warden_id,
                token: user.access_token,
            });

            console.log(updateWardenToEntityReq);

            if (updateWardenToEntityReq.status === false){
                ToastAlert.notifyError(updateWardenToEntityReq.message);
                setSubmitButtonLoading(false);
            } 
            else{
                ToastAlert.notifySuccess(updateWardenToEntityReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeAddWardenPopUp({
                    status: true
                })
            }
        } 
        catch (error) {
            ToastAlert.notifyError("An error occurred while submitting the form.");
            console.error(error);
        } 
    }

    const handleAddWardenSubmission = async (e) => {
        e.preventDefault();
    
        let isPasswordValid = true;
        let isRepeatPasswordValid = true;
    
        setSubmitButtonLoading(true);

        let isFormValid = true;

        if (password === "") {
            isFormValid = false;
            isPasswordValid = false;
            setHasPasswordMessage(true);
            setPasswordMessage("Password is Required.");
            setPasswordMessageType("error");
        } 
        else{
            const isPasswordPatternValid = await validations.validatePassword(password);

            if (isPasswordPatternValid.status === false) {
                isFormValid = false;
                isPasswordValid = false;
                setHasPasswordMessage(true);
                setPasswordMessage("Password is Invalid.");
                setPasswordMessageType("error");
            }
            else{
                isPasswordValid = true;
                setHasPasswordMessage(false);
                setPasswordMessage("");
                setPasswordMessageType("");
            }
        }

        if (repeatPassword === ""){
            isFormValid = false;
            isRepeatPasswordValid = false;
            setHasRepeatPasswordMessage(true);
            setRepeatPasswordMessage("Repeat Password is Required.");
            setRepeatPasswordMessageType("error");
        } 
        else if (repeatPassword !== password){
            isFormValid = false;
            isRepeatPasswordValid = false;
            setHasRepeatPasswordMessage(true);
            setRepeatPasswordMessage("Passwords do not match.");
            setRepeatPasswordMessageType("error");
        } 
        else {
            isRepeatPasswordValid = true;
            setHasRepeatPasswordMessage(false);
            setRepeatPasswordMessage("");
            setRepeatPasswordMessageType("");
        }

        if (!isFormValid) {
            setSubmitButtonLoading(false);
            return;
        }
        try{
            const saveDetailsResponseReq = await WardenServices.saveAdmin({
                email_id: emailID,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                password: password,
                token: user.access_token,
            });

            console.log(saveDetailsResponseReq.data);

            if (saveDetailsResponseReq.status === false){
                ToastAlert.notifyError(saveDetailsResponseReq.message);
                setSubmitButtonLoading(false);
            } 
            else{
                handleUpdateWardenToEntityReq(saveDetailsResponseReq.data);
            }
        } 
        catch (error) {
            ToastAlert.notifyError("An error occurred while submitting the form.");
            console.error(error);
        } 
    }
       
    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            { 
                isEmailVerified === false
                ?
                <form onSubmit={handleEmailValidation} noValidate>
                    <div className="cmsForm">
                        <TextInputRT
                            hasLable={true}
                            lable="Enter Email ID"
                            isMandatory={true}
                            hasMessage={hasEmailIDMessage}
                            message={emailIDMessage}
                        >
                            <input
                                type="text"
                                className="cmsFormStepInputText"
                                placeholder="Enter email"
                                onChange={(e) => setEmailID(e.target.value)}
                                value={emailID}
                            />
                        </TextInputRT>
                        <FormActions>
                            <Button
                                type="submit"
                                bgType="fill"
                                width="auto"
                                bg="dashboard"
                                borderRadius="short"
                                isLoading={submitButtonLoading}
                            >
                                Submit
                            </Button>
                        </FormActions>
                    </div>
                </form> 
                :
                isWardenFound === false
                ?
                <form onSubmit={handleAddWardenSubmission} noValidate>
                    <div className="cmsForm">
                        <TextInputRT
                            hasLable={true}
                            lable="Email ID"
                            isMandatory={false}
                        >
                            <input
                                type="email"
                                className="cmsFormStepInputText"
                                placeholder="Enter Email ID"
                                onChange={(e) => setEmailID(e.target.value)}
                                value={emailID}
                                readOnly={true}
                            />
                        </TextInputRT>
                        <TextInputRT
                            hasLable={true}
                            lable="First Name"
                            isMandatory={false}
                        >
                            <input
                                type="text"
                                className="cmsFormStepInputText"
                                placeholder="Enter first name"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                            />
                        </TextInputRT>
                        <TextInputRT
                            hasLable={true}
                            lable="Last Name"
                            isMandatory={false}
                        >
                            <input
                                type="text"
                                className="cmsFormStepInputText"
                                placeholder="Enter last name"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                            />
                        </TextInputRT>
                        <TextInputRT
                            hasLable={true}
                            lable="Phone Number"
                            isMandatory={false}
                        >
                            <input
                                type="text"
                                className="cmsFormStepInputText"
                                placeholder="Enter phone number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                            />
                        </TextInputRT>
                        <PasswordInputRT
                            hasLable={true}
                            lable="New Password"
                            isMandatory={true}
                            hasMessage={hasPasswordMessage}
                            message={passwordMessage}
                        >
                            <input
                                type={showPassword ? 'text' : 'password'} 
                                className="cmsFormStepInputText"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                             {
                                    showPassword === false
                                    ?
                                    <div className="cmsFormStepInputIcon">
                                        <i className={`fa-solid fa-eye cmsFormStepInputIco clickable`} onClick={() => setShowPassword(true)}></i>
                                    </div>
                                    :
                                    <div className="cmsFormStepInputIcon">
                                        <i className={`fa-solid fa-eye-slash cmsFormStepInputIco clickable`} onClick={() => setShowPassword(false)}></i>
                                    </div>
                                }
                        </PasswordInputRT>
                        <PasswordInputRT
                            hasLable={true}
                            lable="Repeat Password"
                            isMandatory={true}
                            hasMessage={hasRepeatPasswordMessage}
                            message={repeatPasswordMessage}
                        >
                            <input
                                type={showRepeatPassword ? 'text' : 'password'} 
                                className="cmsFormStepInputText"
                                placeholder="Repeat password"
                                onChange={(e) => setRepeatPassword(e.target.value)}
                                value={repeatPassword}
                            />
                             {
                                    showRepeatPassword === false
                                    ?
                                    <div className="cmsFormStepInputIcon">
                                        <i className={`fa-solid fa-eye cmsFormStepInputIco clickable`} onClick={() => setShowRepeatPassword(true)}></i>
                                    </div>
                                    :
                                    <div className="cmsFormStepInputIcon">
                                        <i className={`fa-solid fa-eye-slash cmsFormStepInputIco clickable`} onClick={() => setShowRepeatPassword(false)}></i>
                                    </div>
                                }
                        </PasswordInputRT>
                        <FormActions>
                            <Button 
                                type="submit"
                                bgType="fill"
                                width="auto"
                                bg={submitButtonBg}
                                borderRadius="short"
                                disabled={submitButtonDisabled}
                                isLoading={submitButtonLoading}
                            >
                                {submitButtonLable}
                            </Button>
                            <Button
                                type="button"
                                bgType="fill"
                                width="auto"
                                bg="error"
                                borderRadius="short"
                                action={() => concludeAddWardenPopUp({ status: false })}
                            >
                                Cancel
                            </Button>
                        </FormActions>
                    </div>
                </form>
                :
                <div className="userFoundAddWardenPopUp">
                    <p className="userFoundAddWardenPopUpHeadingTxt">User Found With The Same Email ID</p>
                    <p className="userFoundAddWardenPopUpDescriptionTxt">A User With The <b>Name: {defaults.capitalize(warden?.first_name)} {defaults.capitalize(warden?.last_name)}</b>, and <b>Phone Number: {warden?.phone_data?.phone_number}</b> found with the same Email ID. Would you wan't to assign them as a Warden to your organization?</p>

                    <div className="userFoundAddWardenPopUpActions">
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={false}
                            isLoading={false}
                            action={() => handleUpdateWardenToEntityReq(warden.id)}
                        >
                            Assign User As Warden
                        </Button>
                        <Button
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg="error"
                            borderRadius="short"
                            action={() => console.log("NO")}
                        >
                            Cancel
                        </Button>
                    </div>
                    
                </div>
            }
        </div>
    );
};

export default AddWardenPopUp